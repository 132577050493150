/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.org/docs/use-static-query/
 */

import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import "bootstrap/dist/css/bootstrap.min.css"
import "./layout.css"
import Footer from "../footer/Footer"
import HeaderTemplate from "../header/Header.template"

class Layout extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      xMain: 0,
      yMain: 0,
      xTrailing: 0,
      yTrailing: 0,
      width: 0,
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount = () => {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    this.setState({ width: window.innerWidth })
  }
  handleMouseMove = e => {
    const { clientX, clientY } = e

    this.setState(
      {
        xMain: clientX,
        yMain: clientY,
      },
      () => {
        setTimeout(() => {
          this.setState({
            xTrailing: clientX,
            yTrailing: clientY,
          })
        }, 100)
      }
    )
  }
  render() {
    const { children } = this.props
    const { xMain, yMain, xTrailing, yTrailing, width } = this.state
    return (
      <>
        {width <= 992 ? (
          <>
            <HeaderTemplate />
            <main className="main">{children}</main>
            <Footer />
          </>
        ) : (
          <div onMouseMove={e => this.handleMouseMove(e)}>
            <HeaderTemplate />
            <main className="main">
              {children}{" "}
              <div className="cursors">
                <div
                  className="cursor"
                  style={{
                    left: xMain,
                    top: yMain,
                  }}
                />
                <div
                  className="cursor"
                  style={{
                    left: xTrailing,
                    top: yTrailing,
                  }}
                />
              </div>
            </main>
            <Footer />
          </div>
        )}
      </>
    )
  }
}

export default Layout
