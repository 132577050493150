import React from "react"
import FooterStyle from "./FooterStyle.module.styl"

const Footer = ({ color }) => {
  return (
    <footer className={FooterStyle.footer}>
      <div
        className={
          color ? FooterStyle.footer_content_white : FooterStyle.footer_content
        }
      >
        <div className={color ? FooterStyle.line_white : FooterStyle.line} />©
        2020 ST Solutions
      </div>{" "}
    </footer>
  )
}

export default Footer
