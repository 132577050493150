import React from "react"
import BurgerMenuStyle from "./BurgerMenuStyle.module.styl"
import { Link } from "gatsby"
import Logo from "../../../../assets/images/logo/logo.svg"
import ExitIcon from "../../../../assets/images/exit-icon.svg"
import SplitText from "react-pose-text"

const charPoses = {
  exit: { opacity: 0, y: 0 },
  enter: {
    opacity: 1,
    y: 0,
    delay: ({ charIndex }) => charIndex * 30,
  },
}

function BurgerMenu({ toggleBurgerMenu, openBurgerMenu }) {
  return (
    <div
      className={
        openBurgerMenu === true
          ? BurgerMenuStyle.burger_menu
          : BurgerMenuStyle.burger_menu_closed
      }
    >
      <div className={BurgerMenuStyle.burger_menu_header}>
        <Link to="/" className={BurgerMenuStyle.logo}>
          <img src={Logo} alt="logo" />
        </Link>
        <div
          role="button"
          tabIndex={0}
          onKeyDown={toggleBurgerMenu}
          onClick={toggleBurgerMenu}
          className={BurgerMenuStyle.burger}
        >
          <img src={ExitIcon} alt="burger-menu" />
        </div>
      </div>
      <div
        className={
          openBurgerMenu === true
            ? BurgerMenuStyle.nav_bar
            : BurgerMenuStyle.nav_bar_closed
        }
      >
        <div className={BurgerMenuStyle.nav_bar_menu}>
          <Link
            onClick={toggleBurgerMenu}
            to={"/about"}
            className={BurgerMenuStyle.nav_link}
            activeClassName={BurgerMenuStyle.active_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              About
            </SplitText>
          </Link>
          <Link
            onClick={toggleBurgerMenu}
            to={"/services"}
            className={BurgerMenuStyle.nav_link}
            activeClassName={BurgerMenuStyle.active_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              Services
            </SplitText>
          </Link>
          {/* <Link
            onClick={toggleBurgerMenu}
            to={"/case-studies"}
            className={BurgerMenuStyle.nav_link}
            activeClassName={BurgerMenuStyle.active_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              Case Studies
            </SplitText>
          </Link>
          <Link
            onClick={toggleBurgerMenu}
            to={"/the-book"}
            className={BurgerMenuStyle.nav_link}
            activeClassName={BurgerMenuStyle.active_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              The Book
            </SplitText>
          </Link>
          <a
            onClick={toggleBurgerMenu}
            target="_blank"
            rel="noreferrer"
            href="https://brendanjkane.com/bkblog/"
            className={BurgerMenuStyle.nav_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              Blog
            </SplitText>
          </a>
          <a
            onClick={toggleBurgerMenu}
            target="_blank"
            rel="noreferrer"
            href="https://masterclass.hookpoint.com/"
            className={BurgerMenuStyle.nav_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              Masterclass
            </SplitText>
          </a> */}
          <a
            onClick={() => {
              toggleBurgerMenu()

              document
                .getElementById("newsletter")
                .scrollIntoView({ behavior: "smooth", block: "start" })
            }}
            className={BurgerMenuStyle.nav_link}
          >
            <SplitText initialPose="exit" pose="enter" charPoses={charPoses}>
              Work With Us
            </SplitText>
          </a>
        </div>
      </div>
    </div>
  )
}

export default BurgerMenu
