import React from "react"
import Header from "./components/header/header"
import MobileHeader from "./components/mobileHeader/MobileHeader"
import BurgerMenu from "./components/burgerMenu/BurgerMenu"

class HeaderTemplate extends React.Component {
  constructor(props) {
    super(props)

    this.state = {
      width: 0,
      openBurgerMenu: false,
      path: "path",
    }
  }

  componentDidMount() {
    this.updateWindowDimensions()
    window.addEventListener("resize", this.updateWindowDimensions)
  }

  componentWillUnmount() {
    window.removeEventListener("resize", this.updateWindowDimensions)
  }

  updateWindowDimensions = () => {
    let path = window.location.pathname.slice(1)
    let place = path.search("/", path)
    let result =
      path === "" ? "/" : window.location.pathname.slice(0, place + 600)
    this.setState({ width: window.innerWidth, path: result })
  }

  toggleBurgerMenu = () => {
    this.setState({ openBurgerMenu: !this.state.openBurgerMenu })
    if (this.state.openBurgerMenu) {
      document.body.style.overflow = "auto"
      document.body.style.position = "relative"
    } else {
      document.body.style.overflow = "hidden"
      document.body.style.position = "fixed"
      document.body.style.left = "0"
      document.body.style.right = "0"
      document.body.style.bottom = "0"
      document.body.style.top = "0"
    }
  }

  render() {
    const { width, openBurgerMenu } = this.state
    return (
      <>
        {width >= 992 ? (
          <Header />
        ) : (
          <MobileHeader toggleBurgerMenu={this.toggleBurgerMenu} />
        )}
        {openBurgerMenu === true && (
          <BurgerMenu
            openBurgerMenu={openBurgerMenu}
            toggleBurgerMenu={this.toggleBurgerMenu}
          />
        )}
      </>
    )
  }
}

export default HeaderTemplate
