import MobileHeaderStyle from "./MobileHeaderStyle.module.styl"
import BurgerIcon from "./../../../../assets/images/burger.svg"
import { Link } from "gatsby"
import React from "react"
import Logo from "../../../../assets/images/logo/logo.svg"

const MobileHeader = ({ toggleBurgerMenu }) => {
  return (
    <header className={MobileHeaderStyle.header}>
      <Link to="/" className={MobileHeaderStyle.logo}>
        <img src={Logo} alt="logo" />
      </Link>
      <div
        role="button"
        tabIndex={0}
        onKeyDown={toggleBurgerMenu}
        onClick={toggleBurgerMenu}
        className={MobileHeaderStyle.burger}
      >
        <img src={BurgerIcon} alt="burger-menu" />
      </div>
    </header>
  )
}

export default MobileHeader
