import { Link } from "gatsby"
import HeaderStyle from "./HeaderStyle.module.styl"
import React from "react"
import Logo from "../../../../assets/images/logo/logo.svg"

const Header = () => {
  return (
    <header className={HeaderStyle.header}>
      <div className={HeaderStyle.header_content}>
        <Link to="/" className={HeaderStyle.logo}>
          <img src={Logo} alt="logo" />
        </Link>
        <div className={HeaderStyle.menu}>
          <Link
            to="/about"
            className={HeaderStyle.link}
            activeClassName={HeaderStyle.active_link}
          >
            About
          </Link>
          <Link
            to="/services"
            className={HeaderStyle.link}
            activeClassName={HeaderStyle.active_link}
          >
            Services
          </Link>
          {/* <Link
            to="/case-studies"
            className={HeaderStyle.link}
            activeClassName={HeaderStyle.active_link}
          >
            Case Studies
          </Link>
          <Link
            to="/the-book"
            className={HeaderStyle.link}
            activeClassName={HeaderStyle.active_link}
          >
            The Book
          </Link>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://masterclass.hookpoint.com/"
            className={HeaderStyle.link}
          >
            Masterclass
          </a>
          <a
            target="_blank"
            rel="noreferrer"
            href="https://brendanjkane.com/bkblog/"
            className={HeaderStyle.link}
          >
            Blog
          </a> */}
          <div
            onClick={() => {
              document
                .getElementById("newsletter")
                .scrollIntoView({ behavior: "smooth", block: "start" })
            }}
            style={{ cursor: "pointer" }}
            className={HeaderStyle.link}
          >
            Work With Us
          </div>
        </div>
      </div>
    </header>
  )
}
export default Header
